<script setup>
import {computed} from 'vue';

const emit = defineEmits(['update:checked']);

const props = defineProps({
    checked: {
        type: [Array, Boolean],
        default: false,
    },
    value: {
        type: String,
        default: null,
    },
});

const proxyChecked = computed({
    get() {
        return props.checked;
    },

    set(val) {
        emit('update:checked', val);
    },
});
</script>


<template>
    <input
        v-model="proxyChecked"
        type="checkbox"
        :value="value"
        class="rounded text-emerald-600 hover:text-emerald-500 bg-transparent hover:bg-zinc-500/10 border-zinc-500/40 transition focus:ring-1 focus:ring-emerald-500 dark:focus:ring-emerald-600 dark:focus:ring-offset-zinc-800"
    >
</template>
